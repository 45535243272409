
import { computed, defineComponent, onMounted, ref } from 'vue'
import { statusListAgency } from '@/utils/constants'
import { useFetchData } from '@/composables'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import { CloseBold } from '@element-plus/icons'

export default defineComponent({
  name: 'FilterArea',
  components: {
    CloseBold,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const paramsCatalog = computed({
      get() {
        return props.params
      },
      set(newValue) {
        emit('update-params', newValue)
      },
    })

    const { documents, fetchData } = useFetchData('/catalog-categories', {
      search: null,
      page: 1,
      limit: 100,
    })

    const { getFilterList } = useCatalogRepositories()

    const partnersList = ref<any>([])
    const locationsList = ref<any>([])
    const techniquesList = ref<any>([])
    const printAreasList = ref<any>([])

    const getData = async () => {
      const res = (await getFilterList()) as any
      partnersList.value = res.data.partners
      locationsList.value = res.data.locations
      techniquesList.value = res.data.techniques
      printAreasList.value = res.data.printAreas
    }

    onMounted(() => {
      fetchData()
      getData()
    })

    const typeList = [
      {
        id: 1,
        name: 'POD',
        value: 'pod',
      },
      {
        id: 2,
        name: 'Drop',
        value: 'drop',
      },
    ]
    const clearFilters = () => {
      emit('clear-filters')
    }

    return {
      paramsCatalog,
      documents,
      typeList,
      partnersList,
      locationsList,
      techniquesList,
      printAreasList,
      statusListAgency,
      getData,
      clearFilters,
    }
  },
})
