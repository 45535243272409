
import { computed, defineComponent, reactive, onMounted, ref, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { statusListAgency } from '@/utils/constants'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import FilterArea from './components/FilterArea.vue'
import ItemCard from './components/ItemCard.vue'
import Pagination from '@/components/common/Pagination.vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { sectionLayoutContent, FilterArea, ItemCard, Pagination },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const breadcrumbs = ['Catalog', '']
    const content = computed(() => {
      return { breadcrumbs }
    })
    const resource = `/catalogs`

    const info = JSON.parse(localStorage.getItem('info') || '')
    const params = reactive({
      basecost: info.sellerTeam?.basecost?.id,
      search: route.query.search || null,
      categories: route.query.categories || null,
      status: route.query.status || null,
      partners: route.query.partners || null,
      locations: route.query.locations || null,
      techniques: route.query.techniques || null,
      printAreas: route.query.printAreas || null,
      type: route.query.type || null,
      page: route.query.page || 1,
      limit: route.query.limit || 20,
    })
    const total = ref(0)

    const { getCatalogs } = useCatalogRepositories()

    const catalogs = ref<any>([])
    const getData = async () => {
      const res = (await getCatalogs(params)) as any
      catalogs.value = res.data.data
      total.value = res.data.pagination.total
    }

    const updateURL = () => {
      router.push({
        query: params,
      })
    }

    onMounted(() => {
      getData()
    })

    watch(
      params,
      () => {
        updateURL()
        getData()
      },
      { deep: true }
    )

    const clearFilters = () => {
      params.search = null
      params.categories = null
      params.status = null
      params.partners = null
      params.locations = null
      params.techniques = null
      params.printAreas = null
      params.type = null
      params.page = 1
    }

    return {
      content,
      resource,
      catalogs,
      total,
      params,
      statusListAgency,
      clearFilters,
    }
  },
})
