
import { defineComponent } from 'vue'
import { imageUrl } from '@/utils/image'
import SlotError from '@/components/common/SlotError.vue'

export default defineComponent({
  components: {
    SlotError,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      imageUrl,
    }
  },
})
